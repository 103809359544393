import React, {useEffect, useState} from 'react';

const Banner = (props) => {
    const [isVisible, setVisible] = useState(false);
    useEffect(()=>{
        setVisible(true);
    },[])

    return(
        <div id="home" className="hero-banner">
            <div className= {`container fade-in-section ${isVisible? 'is-visible':''}`}>
                <h1 className="heading">Hi, I'm Yi Liu</h1>
                <h1 className="heading">I'm a Web Developer.</h1>
            </div>
            <div className="downArrow container">
                {/* <h2>Learn more about me</h2> */}
                <svg className="svg-icon arrow-fade" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1718" height="64" width="64">
                    <path d="M956.031 461.954c0-23.955-19.441-43.396-43.396-43.396-10.773 0-20.33 4.346-27.937 10.86l-0.425-0.425-2.235 2.235c-0.044 0.044-0.067 0.063-0.112 0.106l-358.336 358.338c-6.256 6.239-16.377 6.239-22.609 0l-360.685-360.679-0.659 0.659c-7.646-6.685-17.355-11.093-28.298-11.093-23.935 0-43.374 19.441-43.374 43.396 0 15.289 8.389 28.084 20.355 35.812 0.726 1.344 0.576 2.983 1.705 4.112l410.958 410.948c6.233 6.244 16.353 6.244 22.609 0l410.951-410.949c1.279-1.279 1.172-3.108 1.916-4.641 11.522-7.794 19.57-20.336 19.57-35.283zM956.031 149.884c0-23.955-19.441-43.394-43.396-43.394-10.773 0-20.33 4.345-27.937 10.857l-0.425-0.425-2.235 2.235c-0.044 0.045-0.067 0.063-0.112 0.109l-358.337 358.338c-6.256 6.239-16.377 6.239-22.609 0l-360.685-360.683-0.659 0.659c-7.646-6.685-17.355-11.091-28.298-11.091-23.935 0-43.374 19.439-43.374 43.394 0 15.29 8.389 28.087 20.355 35.815 0.726 1.341 0.576 2.98 1.705 4.109l410.959 410.951c6.233 6.239 16.353 6.239 22.609 0l410.951-410.951c1.279-1.277 1.172-3.108 1.916-4.641 11.522-7.794 19.57-20.336 19.57-35.283z" p-id="1719"></path>
                </svg>
            </div>
        </div>
    )
}

export default Banner;